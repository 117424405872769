import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import Titulo from './titulo';
import Corpo from './corpo';

//criando o componente do rodapé para que possa ser exportado
const Frente = ({ page }) => {

  const [itens, setItens] = useState([]);

  useEffect(() => {
    const fetchItens = async () => {
      const url = process.env.REACT_APP_URL_PROD + "/projetos/nome/" + page
      const res = await fetch(url);
      const listItens = await res.json();
      setItens(listItens)
    }
    fetchItens()

  }, [page])

  return (
    <div className="Noticias">
      <div className="boxFrentesDetails p-4 mt-4">
        <Titulo titulo={itens.length !== 0 ? itens[0].nome : ""} />
        <Corpo conteudo={itens.length !== 0 ? itens[0].descricao_longa : ""} imagem={itens.length !== 0 ? itens[0].imagem : ""} />
      </div>
    </div>
  );
};

export default Frente;
