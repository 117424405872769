import React from "react";
// import Frente from "../../components/Frente/index";
// import Membros from "../../components/Membros";
import Header from "../../components/Header";
import Rodape from "../../components/Rodape/index";
import SaibaMais from "../../components/SaibaMais";
import "./style.css"

const FrentesPage = ({ props }) => {
    return (
        <div className="frentesPage">
            <Header />
            <SaibaMais />
            <Rodape />
        </div>
    );
};

export default FrentesPage;
