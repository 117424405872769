//import React from "react";
import React, { useEffect, useState } from "react";
import "../../../src/assets/styles/global.css";
import Slides from "./slides";
import Info from "../../assets/images/info.png";
import "./style.css";


//união de todos os componentes da sessão de anúncios
const SaibaMais = () => {

  const [itens, setItens] = useState([]);

  //Requisicao dos projetos do PET 
  useEffect(() => {

    const fetchItens = async () => {
      const url = process.env.REACT_APP_URL_PROD + "/projetos"
      const res = await fetch(url);
      const listItens = await res.json();
      setItens(listItens)
    }

    fetchItens()
  }, [])

  return (
    <div className="fundoSM">
      <div className="info">
        <hr id="linhaInfo" />
        <img className="imagemInfo" src={Info} alt="Info" />
        <hr id="linhaInfo" />
      </div>

      <h1 className="tituloSM">NOSSAS FRENTES</h1>
      <div className="caixaSM">
        <Slides cards={itens} />
      </div>
    </div>
  );
};

export default SaibaMais;
