import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import axios, { axiosPrivate } from "../../api/axios";
import AddFrenteBox from "../AddFrenteBox";
import FrenteEditCard from "../FrenteEditCard";
const PROJETOS_URL = "/projetos"

//criando o componente do rodapé para que possa ser exportado
const EditFrentes = () => {

  const [itens, setItens] = useState([]);
  const [highlight, setHighlight] = useState("");
  const [action, setAction] = useState("");
  // const [nusp, setUspNumber] = useState("");

  const isDeleted = (data) => {
    setAction(data)
  }

  const editionMode = (data) => {
    setHighlight(data)
  }


  useEffect(() => {
    const getItens = async () => {
      try {
        const response = await axios.get(PROJETOS_URL, {
          headers: { 'Content-Type': 'application/json' },
        });
        setItens(response.data)
      } catch (err) {
        console.error(err);
      }
    }
    getItens();

  }, [action])

  return (
    <div className="boxFrentes">
      <div className="addBox">
        <AddFrenteBox edit={editionMode}></AddFrenteBox>
      </div>
      <div className="editInfoFrentes">
        {
          itens != {} ?
            itens.map((projeto) =>
              <FrenteEditCard projeto={projeto} id={projeto.id} highlight={highlight == projeto.nome ? true : false} deleted={isDeleted} edit={editionMode}></FrenteEditCard>
            ) :
            <></>
        }
      </div>

    </div>
  );
};

export default EditFrentes;
