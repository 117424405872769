import React from "react";
import Card from "react-bootstrap/Card";
import "../../../src/assets/styles/global.css";
import Facebook from "../../assets/images/RedesSociais/facebook.png";
import Github from "../../assets/images/RedesSociais/github.png";
import Instagram from "../../assets/images/RedesSociais/instagram.png";
import Linkedin from "../../assets/images/RedesSociais/linkedin.png";
import "./style.css";

//Bloco responsável por unir os cards de Noticias Pricipais e os cards de Outras Noticias
const BlocoMembros = ({ membros }) => {
  const bloco = membros.map((membro) => (
    <Card className="cardMembro" key={membro.nome} >
      <Card.Img variant="top" className="fotoMembro" src={membro.imagem} />
      <Card.Body className="blocoInfo">
        <Card.Title className="tituloMembro">{membro.nome}</Card.Title>
        <Card.Text className="fraseMembro">{membro.quote}</Card.Text>
        <div className="iconsMembros">
          {membro.facebook && (
            <a href={membro.facebook}>
              <img alt="facebook" className="iconRS" src={Facebook} />
            </a>
          )}
          {membro.linkedin && (
            <a href={membro.linkedin}>
              <img alt="linkedin" className="iconRS" src={Linkedin} />
            </a>
          )}
          {membro.instagram && (
            <a href={membro.instagram}>
              <img alt="instagram" className="iconRS" src={Instagram} />
            </a>
          )}
          {membro.github && (
            <a href={membro.github}>
              <img alt="github" className="iconRS" src={Github} />
            </a>
          )}
        </div>

      </Card.Body>
    </Card>
  ));

  return <>{bloco}</>;
};

export default BlocoMembros;
