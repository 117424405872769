import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import { axiosPrivate } from "../../api/axios";
import DeleteIcon from "./delete.png"
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useHistory } from "react-router-dom";

// import Collapse from 'react-bootstrap/Collapse';
import EditIcon from "./pencil.png"
import Confirm from "../Confirmation/Confirm";
const PROJETOS_URL = "/projetos"


const FrenteEditCard = ({ projeto, id, highlight, deleted, edit }) => {
  const [open, setOpen] = useState(highlight);
  let history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [nome, setNome] = useState(projeto.nome);
  const [data_criacao, setDataCriacao] = useState(projeto.data_criacao);
  const [descricao_curta, setDescricaoCurta] = useState(projeto.descricao_curta);
  const [descricao_html, setDescricaoHtml] = useState(projeto.descricao_html);
  const [descricao_longa, setDescricaoLonga] = useState(projeto.descricao_longa);
  const [imagem, setImagem] = useState(projeto.imagem);
  const [link, setLink] = useState(projeto.link);

  // const [instagram, setInstagram] = useState(projeto.instagram);
  // const [github, setGitgub] = useState(projeto.github);


  const deleteFrente = async () => {
    try {
      const url = PROJETOS_URL + "/" + id
      const response = await axiosPrivate.delete(url, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
      const returnedString = id + "deleted"
      deleted(returnedString)
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!highlight) {
      setOpen(false)
    }
  })

  const editFrenteState = (open) => {
    setOpen(open)
    edit(projeto.nome)
  }

  const editFrente = async (e) => {
    e.preventDefault()
    const url = PROJETOS_URL + "/" + id
    try {
      const response = await axiosPrivate.put(url,
        JSON.stringify({ nome, descricao_curta, descricao_longa, imagem }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      alert("Frente atualizado com sucesso")
      history.go(0)

    } catch (err) {
      alert(err?.response?.mensagem)
    }
  }

  const confirmDeleteFrente = () => {
    deleteFrente()
    hideConfirmation(true)
  }


  const hideConfirmation = (data) => {
    setShowConfirmation(!data)
  }


  return (
    <>
      <div className="cardInfo m-2">
        <div className="h-100 d-flex align-items-center">
          <img className="frenteIcon ml-3 mr-2" src={projeto.imagem} alt="PET Computação"></img>
          <span className="nomePetiano">{projeto.nome}</span>
        </div>
        <div className="iconsBox mr-3">

          <img className="editIcon" aria-controls="example-collapse-text"
            aria-expanded={open} src={EditIcon} onClick={() => editFrenteState(!open)} alt="PET Computação"></img>
          <img className="editIcon" src={DeleteIcon} onClick={() => setShowConfirmation(true)} alt="PET Computação"></img>

        </div>
      </div>
      <Collapse in={open} className="ml-2 mr-2 mb-2">
        <form id="example-collapse-text1" className="editInputBox" onSubmit={editFrente}>
          <div className="input-group mb-2 inputSizeEdition">
            <span className="input-group-text " id="basic-addon30">Nome </span>
            <input type="text" name="nome" onChange={(event) => setNome(event.target.value)} defaultValue={projeto.nome} className="form-control" placeholder="Digite o nome do projeto" aria-label="Username" aria-describedby="basic-addon1" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text" id="basic-addon31">Descricao Curta</span>
            <input type="text" defaultValue={projeto.descricao_curta} onChange={(event) => setDescricaoCurta(event.target.value)} className="form-control" placeholder="Descricao da area do projeto" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          </div>
          <div className="input-group mb-2">
            <span className="input-group-text" id="basic-addon32">Descricao Longa</span>
            <textarea type="text" defaultValue={projeto.descricao_longa} onChange={(event) => setDescricaoLonga(event.target.value)} className="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="Descricao detalhada do projeto" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text">Link da Imagem</span>
            <input type="text" defaultValue={projeto.imagem} onChange={(event) => setImagem(event.target.value)} className="form-control" aria-label="Amount (to the nearest dollar)" placeholder="Insira o link da imagem do projeto" />
          </div>

          <div className="input-group mb-2 d-flex justify-content-end">
            <button type="button" className="btn" onClick={() => setOpen(!open)}>Fechar Edicao</button>
            <button type="submit" className="btn btn-success">Editar</button>
          </div>

        </form>
      </Collapse>
      <Confirm showConfirmation={showConfirmation} hideConfirmation={hideConfirmation} confirmationCallback={confirmDeleteFrente} action="deletar frente"></Confirm>
    </>
  );
};

export default FrenteEditCard;
