import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import AddFrenteForm from "./AddFrenteForm";
import AddFrentePreview from "./AddFrentePreview";

//criando o componente do rodapé para que possa ser exportado
const AddFrenteBox = ({ edit }) => {
  const [show, setShow] = useState(true);

  const hide = (data) => {
    if (!data) {
      edit("")
    }
    setShow(data)
  }

  return (
    <div className="m-2 editFormFrente">
      {show ?
        <AddFrentePreview hidePreview={hide}></AddFrentePreview> :
        <AddFrenteForm hidePreview={hide}></AddFrenteForm>}
    </div>
  );
};

export default AddFrenteBox;
