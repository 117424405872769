import React from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";

function Titulo(props) {
  return (
    <div className="Titulo">
      <header className="border-bottom pb-2">
        <h3>{props.titulo}</h3>
      </header>
    </div>
  );
}

export default Titulo;
