import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./style.css"

const Slides = ({ cards }) => {

  //center Padding
  const CP = () => {
    if (window.innerWidth <= 1000) return "10%";
    if (window.innerWidth >= 1400) return "8%";
    else return "10%";
  };

  //slides to show
  const SS = () => {
    if (window.innerWidth <= 1000) return 1;
    else return 3;
  };

  const arrows = window.innerWidth >= 1000 && window.innerWidth <= 1400;

  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: CP(),
    dots: false,
    infinite: true,
    arrows: arrows,
    slidesToShow: SS(),
  };

  const slide = cards.map((card) => (
    <figure key={card.id} className="figureSM">
      <img alt={card.nome} src={card.imagem} />
      <figcaption>
        <h5>{card.nome}</h5>
        <h3>{card.descricao_curta}</h3>
        <a href={card.link}>
          <button>Saiba Mais</button>
        </a>
      </figcaption>
    </figure>
  ));

  return <Slider {...settings}>{slide}</Slider>;
};

export default Slides;
