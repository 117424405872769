import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import AddPetianoForm from "./AddPetianoForm";
import AddPetianoPreview from "./AddPetianoPreview";

//criando o componente do rodapé para que possa ser exportado
const AddPetianoBox = ({ edit }) => {
  const [show, setShow] = useState(true);

  const hide = (data) => {
    if (!data) {
      edit("")
    }
    setShow(data)
  }

  return (
    <div className="m-2 editFormBody">
      {show ?
        <AddPetianoPreview hidePreview={hide}></AddPetianoPreview> :
        <AddPetianoForm hidePreview={hide}></AddPetianoForm>}
    </div>
  );
};

export default AddPetianoBox;
