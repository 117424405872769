import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../../src/assets/styles/global.css";
import "./style.css";
import { Button } from "react-bootstrap";
import AddIcon from "./adicionar.png"
// import axios, { axiosPrivate } from "../../api/axios";
// import PetianoEditCard from "../PetianoEditCard";
// const PETIANOS_URL = "/petianos"

//criando o componente do rodapé para que possa ser exportado
const AddFrentePreview = ({ hidePreview }) => {

  const hidePrev = () => {
    hidePreview(false)
  }

  return (
    <div className="createPetianoPreview" onClick={() => { hidePrev() }}>
      <img className="addIcon hoverAddIcon" src={AddIcon} alt="Adicionar Frente"></img>
      <h6 className="hoverAddIcon"> Adicionar Frente</h6>
    </div>
  );
};

export default AddFrentePreview;
