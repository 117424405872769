import React from "react";
// import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import "./styles.css";

// Essa função retorna o(s) <Carousel.Item>, ou seja, os slides/páginas visíveis na página inicial.
// Ela recebe por parâmetro um objeto "slides" que contém todos os slides e seus conteúdos.
//
// Para cada item dentro do objeto "slides", ela retorna um <Carousel.Item> com o título (texto principal)
// e botão (e seu texto) especificados no objeto.
function Carrossel(props) {
  // Criamos a variável "slide", que agora irá ter um <Carousel.Item> para cada item passado no objeto "slides".

  // Utilizamos a função map() para que cada item dentro de "slides" seja modificado e receba o conteúdo
  // contido no JSX, ou seja, tudo que está dentro de <Carousel.Item>. Para cada item, modificamos o "tituloSlide"
  // e "botao" para possuírem os valores que foram passados no objeto "slides".
  //
  // ATENÇÃO: Lembre-se que para a função map() funcionar, precisamos passar uma key pra ela, e é por isso
  // (e só por isso) que mantemos o "id" de cada slide.

  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="d-block" src="https://i.imgur.com/BqDXDyh.jpg" alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block" src="https://i.imgur.com/6BLFRx2.jpg" alt="First slide" />
        </Carousel.Item>
      </Carousel>
      <span className="welcome-message">BOAS VINDAS <br />AO SITE DO PET</span>
    </div>

  );
}

export default Carrossel;

