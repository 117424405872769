import React, { useState } from "react";
import "react-bootstrap";
import "../../../../src/assets/styles/global.css";
import "./style.css";
import { useHistory } from "react-router-dom";
import axios, { axiosPrivate } from "../../../api/axios";
// import PetianoEditCard from "../PetianoEditCard";
const PETIANOS_URL = "/petianos"

//criando o componente do rodapé para que possa ser exportado
const AddPetianoForm = ({ hidePreview }) => {
  let history = useHistory();

  const [nusp, setNusp] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [quote, setQuote] = useState("");

  //as datas estao fixas pq essa funcionalidade nao esta sendo utilizada por enquanto
  const [data_entrada, setDataEntrada] = useState("2024-04-01T00:00:00.000Z");
  const [imagem, setImagem] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [github, setGitgub] = useState("");

  const hidePrev = () => {
    hidePreview(true)
  }

  const addPetiano = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(PETIANOS_URL,
        JSON.stringify({ nusp, email, senha, nome, quote, data_entrada, imagem, facebook, linkedin, instagram, github }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      alert("Petiano adicionado com sucesso")
      history.go(0)

    } catch (err) {
      alert(err?.response?.data?.mensagem)
    }
  }


  return (
    <form className="h-100 createPetianoFormBox" onSubmit={addPetiano}>
      <label for="basic-url" className="form-label labelForm">Adicione o petiano abaixo:</label>
      <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon11">Nusp </span>
        <input type="text" className="form-control" onChange={(event) => setNusp(event.target.value)} placeholder="Digite o nups do Petiano" aria-label="Numero usp" aria-describedby="basic-addon1" required />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon10">E-mail </span>
        <input type="text" className="form-control" onChange={(event) => setEmail(event.target.value)} placeholder="Digite o email do Petiano" aria-label="E-mail" aria-describedby="basic-addon1" required />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon0">Senha </span>
        <input type="password" className="form-control" onChange={(event) => setSenha(event.target.value)} placeholder="Digite a senha do Petiano" aria-label="Senha" aria-describedby="basic-addon1" required />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon1">Nome </span>
        <input type="text" className="form-control" onChange={(event) => setNome(event.target.value)} placeholder="Digite o nome do Petiano" aria-label="Nome" aria-describedby="basic-addon1" required />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon2">Frase</span>
        <input type="text" className="form-control" onChange={(event) => setQuote(event.target.value)} placeholder="Digite a frase do Petiado" aria-label="Frase" aria-describedby="basic-addon2" required />
      </div>

      {/* <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon2">Data de entrada no Pet</span>
        <input type="date" className="form-control" onChange={(event) => setDataEntrada(event.target.value)} placeholder="Data de entrada no Pet" aria-label="Data entrada" aria-describedby="basic-addon2" required/>
      </div> */}

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon3">Link da Imagem</span>
        <input type="text" className="form-control" onChange={(event) => setImagem(event.target.value)} id="basic-url" aria-label="link imagem" aria-describedby="basic-addon3" placeholder="Insira o link da imagem(ingur) do Petiano" required />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel">Link do Facebook</span>
        <input type="text" className="form-control" onChange={(event) => setFacebook(event.target.value)} aria-label="link facebook" placeholder="Insira o link do Facebook" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel">Link do Linkedin</span>
        <input type="text" className="form-control" onChange={(event) => setLinkedin(event.target.value)} aria-label="link linkedin" placeholder="Insira o link do Linkedin" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel">Link do Instagram</span>
        <input type="text" className="form-control" onChange={(event) => setInstagram(event.target.value)} aria-label="link instagram" placeholder="Insira o link do Instagram" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel">Link do Git</span>
        <input type="text" className="form-control" onChange={(event) => setGitgub(event.target.value)} aria-label="link github" placeholder="Insira o link do Git" />
      </div>

      <div>
        <button className="btn" onClick={() => hidePrev()} > Cancelar </button>
        <button type="submit" className="btn btn-success" > Criar Petiano </button>
      </div>

    </form>
  );
};

export default AddPetianoForm;
