import React, { useState } from "react";
import "react-bootstrap";
import "../../../../src/assets/styles/global.css";
import "./style.css";
import { useHistory } from "react-router-dom";
import { axiosPrivate } from "../../../api/axios";
// import PetianoEditCard from "../PetianoEditCard";
const PROJETOS_URL = "/projetos"

//criando o componente do rodapé para que possa ser exportado
const AddFrenteForm = ({ hidePreview }) => {
  let history = useHistory();

  const [nome, setNome] = useState("");
  //as datas estao fixas pq essa funcionalidade nao esta sendo utilizada por enquanto
  const [data_criacao, setDataCriacao] = useState("2024-04-01T00:00:00.000Z");
  const [data_encerramento, setDataEncerramento] = useState("2024-04-01T00:00:00.000Z");
  const [descricao_curta, setDescricaoCurta] = useState("");
  //descricao html tambem nao esta sendo usado
  const [descricao_html, setDescricaoHtml] = useState("<html></html>");
  const [descricao_longa, setDescricaoLonga] = useState("");
  const [imagem, setImagem] = useState("");
  const [link, setLink] = useState("");

  const hidePrev = (e) => {
    // e.preventDefault()
    hidePreview(true)
  }

  const addFrente = async (e) => {
    e.preventDefault()
    try {
      const response = await axiosPrivate.post(PROJETOS_URL,
        JSON.stringify({ nome, data_criacao, data_encerramento, descricao_curta, descricao_html, descricao_longa, imagem, link }),
      );
      alert("Projeto adicionado com sucesso")
      history.go(0)

    } catch (err) {
      alert(err?.response?.data?.mensagem)
    }

  }


  return (
    <form className="h-100 createPetianoFormBox" onSubmit={addFrente}>
      <label for="basic-url" className="form-label labelForm">Adicione o projeto abaixo:</label>
      <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon20">Nome </span>
        <input type="text" className="form-control" onChange={(event) => setNome(event.target.value)} placeholder="Nome da frente" aria-label="Username" aria-describedby="basic-addon1" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon21">Vértice da triade </span>
        <input type="text" className="form-control" onChange={(event) => setDescricaoCurta(event.target.value)} placeholder="Vertice da triade(Extensao, Ensino ou Cultura)" aria-label="Descrição curta" aria-describedby="basic-addon1" />
      </div>
      {/* <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon22">Descríção html </span>
        <input type="text" className="form-control" onChange={(event) => setDescricaoHtml(event.target.value)} placeholder="Ex: <html>Apresenta</html>" aria-label="Username" aria-describedby="basic-addon1" />
      </div> */}
      {/* <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon23">Data de criação </span>
        <input type="date" className="form-control" onChange={(event) => setDataCriacao(event.target.value)} placeholder="Digite o nome do Petiano" aria-label="Username" aria-describedby="basic-addon1" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text colorInfoLabel" id="basic-addon24">Data de encerramento: </span>
        <input type="date" className="form-control" onChange={(event) => setDataEncerramento(event.target.value)} placeholder="Digite o nome do Petiano" aria-label="Username" aria-describedby="basic-addon1" />
      </div> */}

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon25">Imagem</span>
        <input type="text" className="form-control" onChange={(event) => setImagem(event.target.value)} placeholder="Link da imagem da frente" aria-label="Recipient's username" aria-describedby="basic-addon2" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel" id="basic-addon26">Link da rota</span>
        <input type="text" className="form-control" onChange={(event) => setLink(event.target.value)} id="basic-url" aria-describedby="basic-addon3" placeholder="ex: Uma palavra que descreva a frente(letra minuscula)" />
      </div>

      <div className="input-group mb-2">
        <span className="input-group-text  colorInfoLabel">Descrição Longa</span>
        <textarea type="text" className="form-control" onChange={(event) => setDescricaoLonga(event.target.value)} aria-label="Amount (to the nearest dollar)" placeholder="Insira a descriçao longa" />
      </div>

      <div>
        <button className="btn" onClick={() => hidePrev()} > Cancelar </button>
        <button type="submit" className="btn btn-success" > Criar Frente </button>
      </div>

    </form>
  );
};

export default AddFrenteForm;
