import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import axios, { axiosPrivate } from "../../api/axios";
import PetianoEditCard from "../PetianoEditCard";
import AddPetianoBox from "../AddPetianoBox";
const PETIANOS_URL = "/petianos"

//criando o componente do rodapé para que possa ser exportado
const EditPetianoScreen = () => {

  const [itens, setItens] = useState([]);
  const [highlight, setHighlight] = useState("");
  const [action, setAction] = useState("");
  // const [nusp, setUspNumber] = useState("");

  const isDeleted = (data) => {
    setAction(data)
  }

  const editionMode = (data) => {
    setHighlight(data)
  }


  useEffect(() => {
    const getItens = async () => {
      try {
        const response = await axios.get(PETIANOS_URL, {
          headers: { 'Content-Type': 'application/json' },
        });
        setItens(response.data)
      } catch (err) {
        console.error(err);
      }
    }
    getItens();

  }, [action])

  return (
    <div className="boxPetianos">
      <div className="addBox">
        <AddPetianoBox edit={editionMode} ></AddPetianoBox>
      </div>
      <div className="editInfoPetiano">
        {
          itens != {} ?
            itens.map((user) =>
              <PetianoEditCard user={user} nusp={user.nusp} highlight={highlight == user.nome ? true : false} deleted={isDeleted} edit={editionMode}></PetianoEditCard>
            ) :
            <></>
        }
      </div>

    </div>
  );
};

export default EditPetianoScreen;
