import React from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";

function Corpo(props) {
  return (
    <div className="Corpo pt-3 pb-3">
      <div className="contentCorpo">{props.conteudo}</div>
      <div className="imagensCorpo">

        {props.imagem && <img alt="img1" className="imgFrente" src={props.imagem} />}
        {/* {props.conteudo.foto2 && <img alt="img" className="border" src={props.conteudo.foto2} />} */}
      </div>
    </div>
  );
}

export default Corpo;
