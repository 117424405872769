import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import axios from "./api/axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PaginaFrente from "./pages/Frente/index";
import PaginaInicial from "./pages/Index";
import MembrosPage from "./pages/Membros";
import FrentesPage from "./pages/FrentePage";
import PaginaLogin from "./pages/Login";
import AdminPage from "./pages/Admin";
const PROJETOS_URL = "/projetos"

const App = () => {
  const [itens, setItens] = useState([]);

  useEffect(() => {
    const getItens = async () => {
      try {
        const response = await axios.get(PROJETOS_URL, {
          headers: { 'Content-Type': 'application/json' },
        });
        setItens(response.data)
      } catch (err) {
        console.error(err);
      }
    }
    getItens();

  }, [])

  // const headers = { 'Content-Type': 'application/json' }

  // //Requisicao dos membros do PET 
  // useEffect(() => {

  //   const fetchItens = async () => {
  //     const res = await fetch("http://localhost:8081/api/projetos/", { headers });
  //     const listItens = await res.json();
  //     setItens(listItens)
  //   }

  //   fetchItens()
  // }, [])

  // if (itens.length > 0) {
  //   frentes = itens.map((frente) => {
  //     return (
  //       <Route key={frente.id} exact path={"/" + frente.nome.toLowerCase()}>
  //         <PaginaFrente props={frente.id} />
  //       </Route>
  //     );
  //   })

  // frentes.push(() => {
  //   return (
  //     <Route exact path={"/"}>
  //       <PaginaInicial />
  //     </Route>
  //   );
  // })
  // }
  //
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <PaginaInicial />
        </Route>
        <Route exact path="/petianos">
          <MembrosPage />
        </Route>
        <Route exact path="/projetos">
          <FrentesPage />
        </Route>
        <Route exact path="/login">
          <PaginaLogin />
        </Route>
        {
          itens.map((projeto, index) =>
            <Route exact path={"/" + projeto.link} key={index}>
              <PaginaFrente props={projeto.link} />
            </Route>
          )
        }
        <Route exact path="/admin">
          <AdminPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
