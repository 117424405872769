import React, { useEffect, useState } from "react";
import "../../../src/assets/styles/global.css";
import Membro from "../../assets/images/member.png";
import BlocoMembros from "./blocoMembros";
import "./style.css";

const Membros = () => {

  const [itens, setItens] = useState([]);

  useEffect(() => {

    const fetchItens = async () => {
      const res = await fetch(process.env.REACT_APP_URL_PROD + "/petianos");
      const listItens = await res.json();
      setItens(listItens)
    }

    fetchItens()
  }, [])

  return (
    <div className="fundoMembros">
      <div className="membro">
        <hr id="linhaMembro" />
        <img className="imagemMembro" src={Membro} alt="Jornal" />
        <hr id="linhaMembro" />
      </div>

      <h1 id="tituloMembros">CONFIRA OS PETIANOS ATUAIS </h1>
      <div className="caixaMembros">
        <BlocoMembros membros={itens} />
      </div>
    </div>
  );
};

export default Membros;
