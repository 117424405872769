import React from "react";
// import Frente from "../../components/Frente/index";
import Membros from "../../components/Membros";
import Header from "../../components/Header";
import Rodape from "../../components/Rodape/index";
import "./style.css"

const MembrosPage = ({ props }) => {
    return (
        <div className="membrosPage">
            <Header />
            <Membros />
            <Rodape />
        </div>
    );
};

export default MembrosPage;
