import React from "react";
import Frente from "../../components/Frente/index";
import Header from "../../components/Header";
import Rodape from "../../components/Rodape/index";
import "./style.css"

const PaginaFrente = ({ props }) => {
  return (
    <div className="detailsFrentesPage">
      <Header />
      <Frente page={props} />
      <Rodape />
    </div>
  );
};

export default PaginaFrente;
