import React, { useEffect, useState } from "react";
import "react-bootstrap";
import "../../../src/assets/styles/global.css";
import "./style.css";
import { axiosPrivate } from "../../api/axios";
import DeleteIcon from "./delete.png"
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useHistory } from "react-router-dom";

// import Collapse from 'react-bootstrap/Collapse';
import EditIcon from "./pencil.png"
import Confirm from "../Confirmation/Confirm";
const PETIANOS_URL = "/petianos"


const PetianoEditCard = ({ user, nusp, highlight, deleted, edit }) => {
  const [open, setOpen] = useState(highlight);
  let history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [nome, setNome] = useState(user.nome);
  const [quote, setQuote] = useState(user.quote);
  const [imagem, setImagem] = useState(user.imagem);
  const [facebook, setFacebook] = useState(user.facebook);
  const [linkedin, setLinkedin] = useState(user.linkedin);
  const [instagram, setInstagram] = useState(user.instagram);
  const [github, setGitgub] = useState(user.github);


  const deleteUser = async () => {
    try {
      const url = PETIANOS_URL + "/" + nusp
      const response = await axiosPrivate.delete(url, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
      const returnedString = nusp + "deleted"
      deleted(returnedString)
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!highlight) {
      setOpen(false)
    }
  })

  const editUserState = (open) => {
    setOpen(open)
    edit(user.nome)
  }

  const editUser = async (e) => {
    e.preventDefault()
    const url = PETIANOS_URL + "/" + nusp
    try {
      const response = await axiosPrivate.put(url,
        JSON.stringify({ nome, quote, imagem, facebook, linkedin, instagram, github }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      alert("Petiano atualizado com sucesso")
      history.go(0)

    } catch (err) {
      alert(err?.response?.mensagem)
    }
  }

  const confirmDeleteUser = () => {
    deleteUser()
    hideConfirmation(true)
  }


  const hideConfirmation = (data) => {
    setShowConfirmation(!data)
  }


  return (
    <>
      <div className="cardInfo m-2">
        <div className="h-100 d-flex align-items-center">
          <img className="userIcon ml-3 mr-2" src={user.imagem} alt="PET Computação"></img>
          <span className="nomePetiano">{user.nome}</span>
        </div>
        <div className="iconsBox mr-3">

          <img className="editIcon" aria-controls="example-collapse-text"
            aria-expanded={open} src={EditIcon} onClick={() => editUserState(!open)} alt="PET Computação"></img>
          {/* <img className="editIcon" src={DeleteIcon} onClick={() => deleteUser()} alt="PET Computação"></img> */}
          <img className="editIcon" src={DeleteIcon} onClick={() => setShowConfirmation(true)} alt="PET Computação"></img>

        </div>
      </div>
      <Collapse in={open} className="ml-2 mr-2 mb-2">
        <form id="example-collapse-text2" className="editInputBox" onSubmit={editUser}>
          <div className="input-group mb-2 inputSizeEdition">
            <span className="input-group-text " id="basic-addon1">Nome </span>
            <input type="text" name="nome" onChange={(event) => setNome(event.target.value)} defaultValue={user.nome} className="form-control" placeholder="Digite o nome do Petiano" aria-label="Username" aria-describedby="basic-addon1" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text" id="basic-addon2">Frase</span>
            <input type="text" defaultValue={user.quote} onChange={(event) => setQuote(event.target.value)} className="form-control" placeholder="Digite a frase do Petiado" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          </div>
          <div className="input-group mb-2">
            <span className="input-group-text" id="basic-addon3">Link da Imagem</span>
            <input type="text" defaultValue={user.imagem} onChange={(event) => setImagem(event.target.value)} className="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="Insira o link da imagem do petiano" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text">Link do Facebook</span>
            <input type="text" defaultValue={user.facebook} onChange={(event) => setFacebook(event.target.value)} className="form-control" aria-label="Amount (to the nearest dollar)" placeholder="Insira o link do Facebook" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text">Link do Linkedin</span>
            <input type="text" defaultValue={user.linkedin} onChange={(event) => setLinkedin(event.target.value)} className="form-control" aria-label="Server" placeholder="Insira o link do Linkedin" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text">Link do Instagram</span>
            <input type="text" defaultValue={user.instagram} onChange={(event) => setInstagram(event.target.value)} className="form-control" aria-label="Server" placeholder="Insira o link do Instagram" />
          </div>

          <div className="input-group mb-2">
            <span className="input-group-text">Link do Git</span>
            <input type="text" defaultValue={user.github} onChange={(event) => setGitgub(event.target.value)} className="form-control" aria-label="Server" placeholder="Insira o link do Git" />
          </div>

          <div className="input-group mb-2 d-flex justify-content-end">
            <button type="button" className="btn" onClick={() => setOpen(!open)}>Fechar Edicao</button>
            <button type="submit" className="btn btn-success">Editar</button>
          </div>

        </form>
      </Collapse>
      <Confirm showConfirmation={showConfirmation} hideConfirmation={hideConfirmation} confirmationCallback={confirmDeleteUser} action="deletar usuario"></Confirm>
    </>
  );
};

export default PetianoEditCard;
