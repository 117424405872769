import React from "react";
import HeaderSite from "../../components/Header";
import Rodape from "../../components/Rodape";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useHistory, useLocation } from "react-router-dom";
import './style.css'
import Membro from "../../assets/images/member.png";
import EditPetianoScreen from "../../components/EditPetiano";
import EditFrentes from "../../components/EditFrentes";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


const AdminPage = () => {

  const [petianos, setPetianos] = useState({});
  const axiosPrivate = useAxiosPrivate();

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const isTokenValid = async () => {
      try {
        await axiosPrivate.get('/auth/token', {
          signal: controller.signal
        });
      } catch (err) {
        console.error(err);
        history.push('/');
      }
    }

    isTokenValid();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  return (
    <div className="bodyAdmin">
      <HeaderSite />

      <div className="admin">
        <hr id="linhaAdmin" />
        <img className="imagemAdmin" src={Membro} alt="Jornal" />
        <hr id="linhaAdmin" />
      </div>
      {/* <div className="d-flex justify-content-center tituloBox"> */}
      {/* </div> */}
      <div className="adminBack">
        <h1 id="tituloAdmin" className=""> Painel de Administrador </h1>


        <div className="adminBoard">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="rowBody">
              <Col sm={2} md={2} lg={1} className="tabsOptions">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item >
                    <Nav.Link eventKey="first">Petianos</Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="second">Projetos</Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="third">Noticias</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={10} md={10} lg={10} className="tabsContent">
                <Tab.Content className="contentBox">
                  <Tab.Pane eventKey="first" className="paneBox">
                    <EditPetianoScreen></EditPetianoScreen>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" className="paneBox">
                    <EditFrentes></EditFrentes>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>

      <Rodape />
    </div>
  );
};

export default AdminPage;
