import React from 'react';
import './style.css';
import '../../../src/assets/styles/global.css'

//importando os logos das redes sociais da pasta assets
import Facebook from '../../assets/images/RedesSociais/IconeFacebook.png'
import Linkedin from '../../assets/images/RedesSociais/IconeLinkedin.png'
import Instagram from '../../assets/images/RedesSociais/IconeInstagram.png'
import Twitter from '../../assets/images/RedesSociais/IconeTwitter.png'
import Pet from '../../assets/images/Logo/Logo.png'

//criando o componente do rodapé para que possa ser exportado
const Rodape = () => {
    return (

        <div className="rodape">

            <div className="linha"> {/*div que contém todas as informações (links, logo do pet e endereço) e as divide em 3 colunas na mesma linha*/}

                {/* coluna 1 */}
                <div className="coluna"> {/*div que contém os links paras as redes socias*/}
                    <h6 className="titulo">NOS SIGA NAS REDES SOCIAIS</h6>
                    <p>
                        <a href="https://www.facebook.com/petcompusp" target='_blank' rel='noopener noreferrer'>
                            <img className="iconeRedesSociais" src={Facebook} alt="facebook link" />
                        </a>

                        <a href="https://www.instagram.com/petcomputacaousp/" target='_blank' rel='noopener noreferrer'>
                            <img className="iconeRedesSociais" src={Instagram} alt="instagram link" />
                        </a>

                        <a href="https://www.linkedin.com/company/petcompusp/about/" target='_blank' rel='noopener noreferrer'>
                            <img className="iconeRedesSociais" src={Linkedin} alt="linkedin link" />
                        </a>

                        <a href="https://twitter.com/PETComp_USP" target='_blank' rel='noopener noreferrer'>
                            <img className="iconeRedesSociais" src={Twitter} alt="twitter link" />
                        </a>
                    </p>
                </div>

                {/* coluna 2 */}
                <div className="coluna"> {/*div que contém o logo do pet*/}
                    <h6 className="titulo">EMAIL</h6>
                    <p>petcomp@icmc.usp.br</p>
                </div>

                {/* coluna 3 */}
                <div className="coluna"> {/*div que contém o endereço do pet*/}
                    <h6 className="titulo">LOCALIZAÇÃO</h6>
                    <p>Av. Trab. São Carlense, 400 - São Carlos/SP<br />
                        Bloco 1, sala 1-103 do ICMC</p>
                </div>
            </div>
        </div >
    );
};

export default Rodape;
