import React from "react";
import Carrossel from "../../components/Carrossel/index.js";
import Header from "../../components/Header";
// import Membros from "../../components/Membros";
import Rodape from "../../components/Rodape";
import SaibaMais from "../../components/SaibaMais";
import "./style.css";

const PaginaInicial = (props) => {
  // Esse array irá controlar a quantidade de slide viísives na página inicial, bem como
  // irá conter o conteúdo de cada slide: título da página e título do texto
  let slides = [];

  slides.push(
    {
      id: 1,
      titulo: "BOAS VINDAS AO SITE DO PET",
      textoBotao: "LEIA MAIS",
      imagemFundo: "https://i.imgur.com/6BLFRx2.jpg",
      linkBotao: "/historia",
    }

  );

  return (
    <div className="totalBody">
      <Header />
      <Carrossel slides={slides}></Carrossel>
      <Rodape />
    </div>
  );
};

export default PaginaInicial;
