import React, { useEffect, useState } from "react";
// import "react-bootstrap";
// import "../../../src/assets/styles/global.css";
// import "./style.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

const Confirm = ({ showConfirmation, hideConfirmation, confirmationCallback, action }) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showConfirmation)
  }, [showConfirmation])

  const handleClose = () => {
    hideConfirmation(true)
  }

  const confimAction = () => confirmationCallback()

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que quer {action}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="primary" onClick={() => confimAction()}>Sim</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Confirm;
