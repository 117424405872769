import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Logo from "../../assets/images/Logo/logo.svg";
import profile from "./profile-user.png";
import "./style.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Dropdown from 'react-bootstrap/Dropdown';
const LOGIN_URL = "/auth"
const TOKEN_URL = '/auth/token'



const HeaderSite = () => {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  let history = useHistory();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('')
  const [username, setUsername] = useState(() => load('nome'))
  // const [results, setResults] = useState(() => load('results'))
  const [show, setShow] = useState(false);
  const [nusp, setNumeroUsp] = useState('');
  const [senha, setSenha] = useState('');
  const [session, setSession] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function load(key) {
    const item = window.sessionStorage.getItem(key);
    return item != null ? item : "";
  }
  useEffect(() => {
    if (show) {
      userRef.current.focus();
    }

  }, [show])

  useEffect(() => {
    setErrMsg('')

  }, [nusp, senha])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const checkToken = async () => {
      try {
        await axiosPrivate.get(TOKEN_URL, {
          signal: controller.signal
        });
        isMounted && setSession(true);

      } catch (err) {
        setSession(false)
        setUsername("")
      }
    }
    checkToken();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  const handleLogout = async () => {

    try {
      const response = await axios.get(LOGIN_URL,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      window.sessionStorage.setItem('nome', "");
      history.go(0)

    } catch (err) {
      if (!err?.response) {
        alert('No server response')
      } else if (err.response?.status === 400) {
        alert(err.response?.mensagem)
      } else if (err.response?.status === 500) {
        alert(err.response?.mensagem)
      } else {
        alert('Logout Failed')
      }
      errRef.current.focus()
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ nusp, senha }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );

      const accessToken = response?.data?.accessToken
      const nome = response?.data?.nome
      window.sessionStorage.setItem('nome', nome);
      setAuth({ nome, nusp, senha, accessToken })
      history.push('/admin')

    } catch (err) {
      if (!err?.response) {
        setErrMsg('No server response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing username or password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
      errRef.current.focus()
    }
  }

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary navbar">
        <Navbar.Brand href="#home" className="brand">
          <img className="logo" src={Logo} alt="PET Computação"></img>
          <a className="nav-link" href="/">PET Computação <span className="sr-only">(current)</span></a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ">
            <Nav.Link href="/" className="linksHeaderSpace linksHeader">Home</Nav.Link>
            <Nav.Link href="/projetos" className="linksHeader">Projetos</Nav.Link>
            <Nav.Link href="#link" className="linksHeader">Noticias</Nav.Link>
            <Nav.Link href="/petianos" className="linksHeader">Petianos</Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            {session
              ? <>
                <Nav.Link href="/admin" className="linksHeader">Painel do Administrador</Nav.Link>
                <Dropdown>
                  <Dropdown.Toggle variant="none" id="dropdown-basic" className="adminBlock">
                    <img className="logo" src={profile} alt="PET Computação"></img>
                    <span className="adminLabel" >{username}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </>
              :
              <Nav.Link className="adminBlock" onClick={handleShow}>
                <img className="logo" src={profile} alt="PET Computação"></img>
                <span className="adminLabel">Login</span>
              </Nav.Link>
            }

          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal show={show} onHide={handleClose} centered>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <Modal.Header closeButton className="titleBlock">
          <img className="logoLogin" src={Logo} alt="PET Computação"></img>
          <Modal.Title className="titleLogin">Login Administrador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="formLogin" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Número USP</Form.Label>
              <Form.Control
                ref={userRef}
                type="number"
                placeholder="ex: 00000000"
                autoFocus
                autoComplete="off"
                onChange={(event => {
                  setNumeroUsp(event.target.value)
                })}
                value={nusp}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Insira a senha"
                onChange={(event => {
                  setSenha(event.target.value)
                })}
                value={senha}
                required
              />
            </Form.Group>
            <div className="d-flex mt-3 mb-2 w-100 justify-content-center" >
              <Button variant="success" type="submit" className="buttonLogin">
                Login
              </Button>
            </div>

          </Form>
        </Modal.Body>
      </Modal>
    </>

    // </Navbar>
  );
}

export default HeaderSite;
